import { createContext } from 'react';

interface ISideNavWidthContextProps {
    isOpen: boolean;
    width: number;
}

const SideNavOpenWidth = 280;
const SideNavClosedWidth = 70;

const SideNavWidthContext = createContext<ISideNavWidthContextProps>({
    isOpen: true,
    width: SideNavOpenWidth,
});

export { SideNavWidthContext, SideNavOpenWidth, SideNavClosedWidth };
