import React from 'react';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

interface ILoadingProps {
    isLoading: boolean;
}

export default function ({ isLoading }: ILoadingProps) {
    if (!isLoading) return null;
    return (
        <Grid container justifyContent="center" marginTop={4}>
            <Grid item xs={2} style={{ textAlign: 'center' }}>
                <CircularProgress color="primary" />
            </Grid>
        </Grid>
    );
}
