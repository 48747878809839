import React from "react"

// Create the layout component
class AugmentingLayout extends React.Component<any, any> {
    render() {
        const {
            getComponent
        } = this.props

        const BaseLayout = getComponent("BaseLayout", true)

        return (
            <div>
                <BaseLayout />
            </div>
        )
    }
}

// Create the plugin that provides our layout component
export const AugmentingLayoutPlugin = () => {
    return {
        components: {
            AugmentingLayout: AugmentingLayout
        }
    }
}