import React from 'react';
import Dialog from '@mui/material/Dialog';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function ({
    title,
    message,
    handleResult,
}: {
    title: string;
    message: string;
    handleResult: (confirmed: boolean) => void;
}) {
    return (
        <Dialog open={true} maxWidth="sm" fullWidth>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Typography>{message}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleResult(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => handleResult(true)} color="secondary">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}
