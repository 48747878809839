import { TaskStatus, TaskStatusType } from "../../types/TaskStatus";

export function getTaskStatusColor (status: TaskStatusType) {
    switch(status) {
        case TaskStatus.created:
            return "textPrimary";
        case TaskStatus.inprogress:
            return "#00838f";
        case TaskStatus.completed:
            return "green";
        case TaskStatus.failed:
            return "red";
    }
    return "textPrimary"
}   