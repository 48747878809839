import IEdgeDevice from '../../types/IEdgeDevice';
import { DateTime } from 'luxon';
import { getLocalDateTime } from '../../util';

export const getStatus = (device: IEdgeDevice) => {
    if (device.last_heartbeat) {
        // the Device is Online, if there was a Hearbeat in the last five minutes

        const beat = DateTime.fromISO(device.last_heartbeat, { zone: 'utc' });
        const diffNow = beat.diffNow('minutes');

        return diffNow.minutes > -5 ? 'Online' : 'Offline';
    }
    return 'waiting for registration';
};

export const getHeartbeatTime = (heartbeat?: string) => {
    if (!heartbeat) {
        return 'Never';
    }
    return getLocalDateTime(heartbeat);
};
