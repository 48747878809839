import React from 'react';
import Grid from '@mui/material/Grid';

interface INoDataYetProps {
    data: Array<unknown>;
    name: string;
    isLoading?: boolean;
}

export default function ({ data, name, isLoading }: INoDataYetProps) {
    if (data.length !== 0 || isLoading) return null;
    return (
        <Grid container justifyContent="center" marginTop={4}>
            <Grid item xs={2} style={{ textAlign: 'center' }}>
                No {name} yet...
            </Grid>
        </Grid>
    );
}
