import Box from '@mui/material/Box';
import React from 'react';
import hsosLogo from '../../imgs/hsos-logo-compact.png';

export default function HSOSLogo() {
    return (
        <Box sx={{ mr: 2 }}>
            <img src={hsosLogo} height="40px" />
        </Box>
    );
}
