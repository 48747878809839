import React, { ReactNode } from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#0f5432',
            contrastText: '#fff',
        },
        secondary: {
            main: '#ba000d',
        },
    },
});

export default function AppThemeProvider({ children }: { children: ReactNode }) {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
