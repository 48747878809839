// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#open-data-map {
  position: absolute !important;
  top: 64px;
  bottom: 30px;
  right: 0;
}

.open-data-map-sidenav-open {
  /* DOM update does not work properly, so just let the map extend underneath the SideNav
  left: 280px;*/
  left: 70px;
}

.open-data-map-sidenav-closed {
  left: 70px;
}

#open-data-type-select-card {
  right: 30px;
}

#open-data-type-select-card-content {
  padding: 10px;
  width: 150px;
}

#geographic-data-menu-card {
  left: 330px;
}

#weather-card {
  top: 150px;
}

#search-location-card {
  padding: '2px 4px';
  display: 'flex';
  align-items: 'center';
  width: 300;
}

#geographic-data-menu-card-content {
  /*padding: 10px;*/
  width: 300px;
}

.leaflet-control-zoom {
  display: none;
}

.map-overlay-card {
  z-index: 500;
  position: absolute;
  top: 84px;
}

.map-overlay-left-sidenav-open {
  left: 310;
}

.map-overlay-left-sidenav-closed {
  left: 70;
}

`, "",{"version":3,"sources":["webpack://./src/style/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,6BAA6B;EAC7B,SAAS;EACT,YAAY;EACZ,QAAQ;AACV;;AAEA;EACE;eACa;EACb,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,qBAAqB;EACrB,UAAU;AACZ;;AAEA;EACE,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,QAAQ;AACV","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n#open-data-map {\n  position: absolute !important;\n  top: 64px;\n  bottom: 30px;\n  right: 0;\n}\n\n.open-data-map-sidenav-open {\n  /* DOM update does not work properly, so just let the map extend underneath the SideNav\n  left: 280px;*/\n  left: 70px;\n}\n\n.open-data-map-sidenav-closed {\n  left: 70px;\n}\n\n#open-data-type-select-card {\n  right: 30px;\n}\n\n#open-data-type-select-card-content {\n  padding: 10px;\n  width: 150px;\n}\n\n#geographic-data-menu-card {\n  left: 330px;\n}\n\n#weather-card {\n  top: 150px;\n}\n\n#search-location-card {\n  padding: '2px 4px';\n  display: 'flex';\n  align-items: 'center';\n  width: 300;\n}\n\n#geographic-data-menu-card-content {\n  /*padding: 10px;*/\n  width: 300px;\n}\n\n.leaflet-control-zoom {\n  display: none;\n}\n\n.map-overlay-card {\n  z-index: 500;\n  position: absolute;\n  top: 84px;\n}\n\n.map-overlay-left-sidenav-open {\n  left: 310;\n}\n\n.map-overlay-left-sidenav-closed {\n  left: 70;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
