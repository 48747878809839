import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { AlertColor } from '@mui/material/Alert';
import { ReactNode } from 'react';

export default function ({
    message,
    severity,
    open,
    sticky = false,
    onClose,
    anchorOrigin = { vertical: 'top', horizontal: 'right' },
}: {
    message?: string | ReactNode;
    severity: AlertColor | undefined;
    open: boolean;
    sticky?: boolean;
    onClose: () => void;
    anchorOrigin?: SnackbarOrigin;
}) {
    return (
        <Snackbar anchorOrigin={anchorOrigin} open={open} autoHideDuration={sticky ? null : 4000} onClose={onClose}>
            <Alert severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
}
